<template>
  <div class="col-12">
    &#169; 2022 Cyrill Martin - <a href="https://kmapper.com" target="_blank">kmapper GmbH</a> -
    <a href="https://github.com/cyrill-martin/swisspolls" target="_blank"
      >GitHub</a
    >
  </div>
</template>

<style scoped>
div {
  font-size: 0.8rem;
}
</style>